.scroll-effect-title1, .scroll-effect-title2, .scroll-effect-title3, .scroll-effect-title4, .scroll-effect-title5, .scroll-effect-title6, .scroll-effect-subtitle1, .scroll-effect-subtitle2, .scroll-effect-subtitle3, .scroll-effect-subtitle4, .scroll-effect-subtitle5, .scroll-effect-subtitle6, .scroll-effect-img1, .scroll-effect-img2, .scroll-effect-btn, .scroll-effect-ctn-principios, .scroll-effect-ctn-compromisso-consideracao {
  visibility: hidden;
}

.box {
    --clr-shadow__border: #d1832a;
    --clr-text: #F6F4EB;
    --clr-space: #120e1e;
    --clr-space-gr: #D2691E;
    --clr-star: #E9F8F9;
    --size: 3rem;
    position: relative;
    outline: 1px solid var(--clr-shadow__border);
  }
  
  .button {
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    background: transparent;
    padding: calc(var(--size) / 3) var(--size);
    border: none;
    cursor: pointer;
    color: var(--clr-text);
    text-shadow: 2px 0px var(--clr-shadow__border), 0px 2px var(--clr-shadow__border),
    -2px 0px var(--clr-shadow__border), 0px -2px var(--clr-shadow__border);
  }
  
  .space {
    width: 100%;
    height: 100%;
    bottom: 0%;
    gap: 1.5rem;
    transition: 0.5s ease-in-out;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    display: flex;
    background: linear-gradient( 160deg, var(--clr-space), var(--clr-space-gr));
  }
  
  .box:hover .space {
    opacity: 1;
  }
  
  .star {
    height: 4rem;
    width: 0.3rem;
    transition: 0.5s;
    border-radius: 50px;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    position: relative;
    background-color: var(--clr-star);
    animation: space-animation calc(0.1s * var(--i)) linear infinite;
  }
  
  @keyframes space-animation {
    0% {
      transform: rotate(-30deg) translateY(calc(-52% * var(--i)));
    }
  
    100% {
      transform: rotate(-30deg) translateY(calc(52% * var(--i)));
    }
  }
  