.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
  }
  
.item {
  border-radius: 1rem;
  overflow: hidden;
  transition: 0.4s;
}
  
  .item img, video {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .item:hover {
    filter: brightness(1.3);
   }